import axios from "axios";
import debounce from "lodash.debounce";
import { useContext } from "react";
import { useNavigate } from "react-router";
import Select, { SingleValue } from "react-select";
import AsyncSelect from "react-select/async";
import ChevronRight from "../assets/chevron-right.svg";
import { TreatmentPlanContext } from "../contexts/TreatmentPlanContext";
import { Patient } from "../interfaces";
interface PatientOption {
  label: string;
  value: Patient;
}

const customStyles = {
  container: (base: any) => ({
    ...base,
    flex: 1,
    boxShadow: "none",
    width: "100%",
  }),
  control: (styles: any) => ({
    ...styles,
    width: "100%",
    boxShadow: "none",
    border: 0,
    fontSize: 14,
    fontColor: "#99999A",
    fontWeight: 200,
  }),
  indicatorSeparator: (state: any) => ({
    display: "none",
  }),
};

const practitionerOptions = [
  { value: "Dr Andy Owen", label: "Dr Andy Owen" },
  { value: "Dr Anne Craig", label: "Dr Anne Craig" },
  { value: "Dr Annie Borland", label: "Dr Annie Borland" },
  { value: "Dr Clare Halford", label: "Dr Clare Halford" },
  { value: "Dr Daniela Reyes", label: "Dr Daniela Reyes" },
  { value: "Dr Denise Stapleton", label: "Dr Denise Stapleton" },
  { value: "Dr Eileen McManus", label: "Dr Eileen McManus" },
  { value: "Dr Eu Jin Lee", label: "Dr Eu Jin Lee" },
  { value: "Dr Grace Tong", label: "Dr Grace Tong" },
  { value: "Dr Grace Lavelli", label: "Dr Grace Lavelli" },
  { value: "Dr James Cameron", label: "Dr James Cameron" },
  { value: "Dr Mike Foster", label: "Dr Mike Foster" },
  { value: "Dr Monica MacRae", label: "Dr Monica MacRae" },
  { value: "Dr Raja Antoine", label: "Dr Raja Antoine" },
  { value: "Dr Sean Cameron", label: "Dr Sean Cameron" },
  { value: "Dr Wei Long", label: "Dr Wei Long" },
  { value: "Dr Waseem Alzaher", label: "Dr Waseem Alzaher" },
  { value: "Dr William Parkyn", label: "Dr William Parkyn" },
  { value: "Dr Ximena Hunefeldt", label: "Dr Ximena Hunefeldt" },
  { value: "Ben Whitson", label: "Ben Whitson" },
  { value: "RN Maxine Anderson", label: "RN Maxine Anderson" },
  { value: "RN Robyn Hewitt", label: "RN Robyn Hewitt" },
  { value: "RN Shannon Norton", label: "RN Shannon Norton" },
  { value: "RN Aarti Shenoy", label: "RN Aarti Shenoy" },
  { value: "RN Maddie van Maanen", label: "RN Maddie van Maanen" },
  { value: "RN Penny Siegrist", label: "RN Penny Siegrist" },
  { value: "Dr Kav Naidoo", label: "Dr Kav Naidoo" },
  { value: "Dr Cliff Pryor", label: "Dr Cliff Pryor" },
  { value: "Dr Liam Tranter", label: "Dr Liam Tranter" },
  { value: "Dr Stella Goulart", label: "Dr Stella Goulart" },
  { value: "Dr Varun Thirayan", label: "Dr Varun Thirayan" },
  { value: "Dr William Ritchie", label: "Dr William Ritchie" },
  { value: "Dr Alannah Stockwell", label: "Dr Alannah Stockwell" },
  { value: "Dr Zoe Cruse", label: "Dr Zoe Cruse" },
  { value: "Dr Oscar Deng", label: "Dr Oscar Deng" },
  { value: "Dr Anna Harvey", label: "Dr Anna Harvey" },
  { value: "RN Margaret Hewett-Hawker", label: "RN Margaret Hewett-Hawker" },
  { value: "Cannabis Clinic Team", label: "Cannabis Clinic Team" },
  { value: "RN Anna Cuttance", label: "RN Anna Cuttance" },
  { value: "Dr Aysar Mansoor", label: "Dr Aysar Mansoor" },
  { value: "Dr Minet van Zyl", label: "Dr Minet van Zyl" },
  { value: "Dr Francesca Kelly", label: "Dr Francesca Kelly" },
  { value: "Dr Emma Mayo", label: "Dr Emma Mayo" },
  { value: "Dr Nawar Mansoor", label: "Dr Nawar Mansoor" },
  { value: "Dr Nisal Kuruppu", label: "Dr Nisal Kuruppu" },
  { value: "RN Geri Harvey", label: "RN Geri Harvey" },
  { value: "Dr Lawrie Hughes", label: "Dr Lawrie Hughes" },
  { value: "Dr Lizzie Shiner", label: "Dr Lizzie Shiner" },
  { value: "Dr Taisia Cech", label: "Dr Taisia Cech" },
  { value: "Dr Jade Russell", label: "Dr Jade Russell" },
  { value: "Dr Rachel Beresford", label: "Dr Rachel Beresford" },
  { value: "RN Genevieve Harris", label: "RN Genevieve Harris" },
  { value: "RN Sara Wilson", label: "RN Sara Wilson" },
  { value: "Dr Amanda Henderson", label: "Dr Amanda Henderson" },
  { value: "Dr Shahin Khalique", label: "Dr Shahin Khalique" },

  { value: "Dr Michael Billingsley", label: "Dr Michael Billingsley" },
  { value: "Dr Adam Sangster", label: "Dr Adam Sangster" },
  { value: "Dr Daniel Strange", label: "Dr Daniel Strange" },
  { value: "Dr John Gibbins", label: "Dr John Gibbins" },

  { value: "Dr Shehnaz Hussain", label: "Dr Shehnaz Hussain" }, // Shehnaz.H@cannabisclinic.co.nz
  { value: "Dr Vittoria Draghi", label: "Dr Vittoria Draghi" }, // Vittoria.D@cannabisclinic.co.nz
  { value: "Dr Tushar Srivastava", label: "Dr Tushar Srivastava" }, // Tushar.S@cannabisclinic.co.nz
];

export const FindPatient = () => {
  const navigate = useNavigate();
  const { treatmentPlan, updateTreatmentPlanField } =
    useContext(TreatmentPlanContext);

  const getPatients = async (inputValue: string): Promise<PatientOption[]> => {
    try {
      const patientsData = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}acuity/clients`,
        {
          params: {
            searchInput: inputValue,
          },
        }
      );

      return patientsData.data.map((patient: Patient) => ({
        label:
          patient.firstName + " " + patient.lastName + " - " + patient.phone,
        value: {
          email: patient.email,
          firstName: patient.firstName,
          lastName: patient.lastName,
          notes: patient.notes,
          phone: patient.phone,
        },
      }));
    } catch (err: any) {
      if (err.response.data.message) {
        console.log(err);
        alert(err.response.data.message);
      } else {
        alert(err);
      }
      return [];
    }
  };

  const _loadOptions = (
    inputValue: string,
    callback: (options: PatientOption[]) => void
  ) => {
    getPatients(inputValue).then((patients) => {
      callback(patients);
    });
  };

  const loadOptions = debounce(_loadOptions, 1000);

  // Checks to make sure user has filled out the required fields before submission
  const submit = () => {
    if (
      !treatmentPlan.practitioner &&
      !treatmentPlan.patient &&
      !treatmentPlan.appointmentType
    )
      return;
    navigate("/create-treatment-plan");
  };

  return (
    <div>
      <div className="mb-8 text-3xl md:text-4xl font-[1000]">
        Find a patient
      </div>
      <div className="flex gap-7 flex-col md:flex-row">
        <div className="flex flex-col flex-1">
          <div className="px-7 py-3 text-md sm:text-lg border rounded-xl w-full font-semibold">
            <div className="my-3 text-lg">Select practitioner</div>
            <div className="border-[1px] rounded-3xl px-3 mb-4">
              <Select
                styles={customStyles}
                onChange={(practitioner) =>
                  updateTreatmentPlanField("practitioner", practitioner?.value)
                }
                value={
                  treatmentPlan.practitioner
                    ? {
                        label: treatmentPlan.practitioner,
                        value: treatmentPlan.practitioner,
                      }
                    : null
                }
                options={practitionerOptions}
                placeholder="Type in Doctor / Nurse name or select from dropdown..."
              />
            </div>

            <div className="flex flex-col mb-5">
              <div className="my-3">Select type of appointment</div>
              <div className="flex flex-row">
                <div className="flex items-center">
                  <input
                    type="radio"
                    id="appointmentType"
                    name="appointmentType"
                    checked={treatmentPlan.appointmentType === "new"}
                    onChange={() => {
                      updateTreatmentPlanField("appointmentType", "new");
                    }}
                    style={{ boxShadow: "0" }}
                  />
                  <div className="mx-2 text-base font-light">New</div>
                </div>

                <div className="flex items-center ml-5">
                  <input
                    type="radio"
                    id="appointmentType"
                    name="appointmentType"
                    checked={treatmentPlan.appointmentType === "followUp"}
                    onChange={() => {
                      updateTreatmentPlanField("appointmentType", "followUp");
                    }}
                  />
                  <div className="mx-2 text-base font-light">Follow Up</div>
                </div>

                <div className="flex items-center ml-5">
                  <input
                    type="radio"
                    id="appointmentType"
                    name="appointmentType"
                    checked={treatmentPlan.appointmentType === "productRepeats"}
                    onChange={() => {
                      updateTreatmentPlanField(
                        "appointmentType",
                        "productRepeats"
                      );
                    }}
                  />
                  <div className="mx-2 text-base font-light">
                    Product Repeats
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col mb-6">
              <div className="my-3 mr-32">Select Patient</div>
              <div className="border-[1px] rounded-3xl px-3">
                <AsyncSelect
                  cacheOptions
                  loadOptions={loadOptions}
                  onChange={(
                    patient: SingleValue<PatientOption | undefined>
                  ) => {
                    if (patient) {
                      updateTreatmentPlanField("patient", patient.value);
                    }
                  }}
                  value={
                    treatmentPlan.patient
                      ? {
                          label: `${treatmentPlan.patient.firstName} ${treatmentPlan.patient.lastName} - ${treatmentPlan.patient.phone}`,
                          value: treatmentPlan.patient,
                        }
                      : null
                  }
                  noOptionsMessage={() => "Search for a patient..."}
                  styles={customStyles}
                  placeholder="Type in Patient Name or select from dropdown..."
                />
              </div>
            </div>
          </div>

          {treatmentPlan.patient &&
            treatmentPlan.practitioner &&
            treatmentPlan.appointmentType && (
              <div className="flex flex-col px-8 text-sm border sm:text-md rounded-xl py-7 mt-7">
                <div className="flex flex-col">
                  <div className="my-2 text-xl sm:text-2xl">
                    {treatmentPlan.patient.firstName +
                      " " +
                      treatmentPlan.patient.lastName}
                  </div>
                  <div className="flex my-2">
                    Email:
                    <div className="mx-2 font-light">
                      {treatmentPlan.patient.email}
                    </div>
                  </div>
                  <div className="flex my-2">
                    Phone:
                    <div className="mx-2 font-light">
                      {treatmentPlan.patient.phone}
                    </div>
                  </div>
                  {treatmentPlan.patient.notes && (
                    <div className="flex my-2">
                      Notes:
                      <div className="mx-2 font-light">
                        {treatmentPlan.patient.notes}
                      </div>
                    </div>
                  )}
                  <div className="flex my-2">
                    DOB:
                    <div className="mx-2 font-light">Not in acuity</div>
                  </div>
                </div>

                <button
                  className="flex items-center ml-auto mt-auto px-4 py-2.5 bg-[#5197DE] text-white text-xs sm:text-sm rounded-3xl"
                  onClick={() => submit()}
                >
                  Select Patient
                  <img src={ChevronRight} alt="chevronRight" className="ml-2" />
                </button>
              </div>
            )}
        </div>
        <div className="bg-patient_art bg-cover flex flex-col flex-1 border-[1px] rounded-3xl px-3 sm: mb-80 text-md sm:text-lg">
          <div className="px-4">
            <div className="text-xl sm:text-2xl mt-16 font-bold">
              Need a hand?
            </div>
            <div className="mt-4">
              Feel free to contact our team at <br />
              Cannabis Clinic
            </div>

            <button
              onClick={() =>
                window.open("https://cannabisclinic.co.nz/contact/")
              }
              className="flex items-center mr-auto mt-7 mb-9 px-5 py-2.5 bg-[#5197DE] text-white text-xs sm:text-sm rounded-3xl hover:cursor-pointer"
            >
              Contact Us
              <img src={ChevronRight} alt="chevronRight" className="ml-2" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
